import request from '@/utils/request'

export function getOrderInfoByOrderNo(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getOrderInfo`,
    method: 'post',
    data
  })
}

export function sendMail(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/saveMessage`,
    method: 'post',
    data
  })
}
