<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="$t('page.timedSend')"
    :visible.sync="visible"
    center
    width="400px"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top" size="small">
      <el-form-item :label="$t('page.timedSendLabel')" prop="time">
        <el-date-picker
          v-model="form.time"
          :placeholder="$t('page.selectPlaceholder')"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
        <div v-if="form.time">
          {{ `本邮件将在 ${dayjs(form.time).format('YYYY年MM月DD日 HH:mm:ss')} 投递到对方邮箱` }}
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="handleClose">
        {{ $t("order.Cancel") }}
      </el-button>
      <el-button :loading="submitLoading" size="small" type="primary" @click="handleSubmit">
        {{ $t("page.sure") }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { sendMail } from '@/views/rma-manage/send-email/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  name: 'SendRegularlyDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => ({})
    },
    queryId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      submitLoading: false,
      form: {
        time: ''
      },
      rules: {
        time: [
          {
            required: true,
            message: this.$t('page.selectPlaceholder'),
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请选择定时时间'))
              } else {
                // value是utc时间，判断当前时间是否在value之前
                if (dayjs(value).isBefore(dayjs(dayjs().utc().format('YYYY-MM-DD HH:mm:ss')))) {
                  callback(new Error('您设置的时间已过期'))
                } else {
                  callback()
                }
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    dayjs() {
      return dayjs
    },
    submitParams() {
      return {
        ...this.params,
        sendRegularlyTime: this.form.time
      }
    }
  },
  methods: {
    handleClose() {
      if (this.submitLoading) {
        this.$message('正在执行中，请稍后关闭')
        return
      }
      this.form.time = ''
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.submitLoading = true
          const { msg } = await sendMail(this.submitParams).finally(() => {
            this.submitLoading = false
          })
          this.$message.success(msg)
          if (this.queryId) {
            location.href = `${process.env.VUE_APP_MIRMILES_API}/after-sales/audit-list`
          } else {
            this.$router.go(-1)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
