<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="auto" size="small">
      <el-row :gutter="20">
        <el-col :span="8">
          <!-- 平台 -->
          <el-form-item :label="$t('title.platform')" prop="platform">
            <el-select
              v-model="form.platform"
              :disabled="!!queryId"
              :placeholder="$t('page.selectPlaceholder')"
              filterable
              @change="handlePlatformChange"
            >
              <el-option
                v-for="item in platformOptions"
                :key="item.id"
                :label="item.platformCode"
                :value="item.platformCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--店铺-->
          <el-form-item :label="$t('title.shopName')" prop="shop">
            <el-select v-model="form.shop" :disabled="!!queryId" clearable filterable @change="handleShopChange">
              <el-option
                v-for="item in shopOptions"
                :key="item.id"
                :label="item.account"
                :value="item.account"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--站点-->
          <el-form-item :label="$t('title.site')" prop="site">
            <el-select v-model="form.site" :disabled="!!queryId" clearable filterable @change="getOrderInfo">
              <el-option
                v-for="item in siteOptions"
                :key="item.id"
                :label="item.platformSite"
                :value="item.platformSite"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--订单号-->
          <el-form-item :label="form.platform === 'walmart' ? 'Purchase Order' : $t('title.ordernumber')" prop="orderCoder">
            <el-input
              v-model="form.orderCoder"
              :disabled="!!queryId"
              :placeholder="$t('title.Afterentering')"
              clearable
              @keyup.enter.native="getOrderInfo"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--客户ID-->
          <el-form-item :label="$t('title.CustomerID')" prop="buyerId">
            <el-input v-model="form.buyerId" :placeholder="$t('page.inputPlaceholder')" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--买家邮箱-->
          <el-form-item :rules="rules.recipientId" label="客户邮箱" prop="messageDetailVOList.0.recipientId">
            <el-input v-model="form.messageDetailVOList[0].recipientId" :placeholder="$t('page.inputPlaceholder')" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--标签-->
          <el-form-item :label="$t('title.ProblemMentioned')" prop="tag">
            <el-cascader
              v-model="form.tag"
              :disabled="!!queryId"
              :options="tagData"
              :props=" { checkStrictly: true, value: 'id', label: language === 'en' ? 'labelNameEN' : 'labelName', children: 'childNodes' }"
              clearable
              filterable
            >
              <template v-slot="{ data }">
                <span v-if="language === 'en-US'">{{ data.labelNameEN }}</span>
                <span v-else>{{ data.labelName }}</span>
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--主题-->
          <el-form-item :label="$t('title.topic')" :rules="rules.subject" prop="messageDetailVOList.0.subject">
            <el-input v-model="form.messageDetailVOList[0].subject" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label-width="0">
            <el-select
              v-model="topicTemplate"
              :placeholder="$t('page.searchTopicTemplateTip')"
              clearable
              filterable
              value-key="id"
              @change="handleTopicTemplateChange"
            >
              <el-option
                v-for="item in topicTemplateList"
                :key="item.id"
                :label="item.typeName + '/' + item.templateTitle"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--附件-->
    <div class="attachment">
      <upload
        ref="upload"
        v-model="fileList"
        :action="ossUrl"
        :limit="5"
        :show-file-list="false"
        :size-limit="10 * 1024 * 1024"
        accept=".JPG,.PNG,.GIF,.HTML,.PDF,.TXT,.DOC,.DOC,.DOCX,.XLSX,.XLS,.MP4,.MP3,.ZIP,.7Z,.TGZ"
        list-type="text"
        style="display: inline-block"
        @finish="uploading.close()"
        @progress="handleUpload"
      >
        <el-button icon="el-icon-folder-add" size="small">
          {{ $t('page.sendMailUploadTitle', {size: '10M'}) }}
        </el-button>
      </upload>
      <div style="display: flex;align-items: center;flex-wrap: wrap">
        <div v-for="(item, index) in fileList" :key="item.signatureUrl" class="el-upload-list__item is-success" style="margin-top: 0; width: auto">
          <a class="el-upload-list__item-name">
            <i class="el-icon-document" />
            {{ `${item.name}(${getFileLimitMessage(item.size)})` }}
          </a>
          <label class="el-upload-list__item-status-label">
            <i class="el-icon-upload-success el-icon-circle-check" />
          </label>
          <i class="el-icon-close" @click="handleRemove(index)" />
        </div>
      </div>
    </div>
    <el-form ref="contentForm" :model="form" :rules="rules" label-width="auto" size="small">
      <!--正文模板-->
      <el-form-item :label="$t('page.mainTemplate')">
        <el-select
          v-model="mainTemplate"
          clearable
          filterable
          @change="handleMainTemplateChange"
        >
          <el-option
            v-for="item in contentTemplateList"
            :key="item.id"
            :label="item.typeName + '/' + item.templateTitle"
            :value="item.templateContent"
          />
        </el-select>
      </el-form-item>
      <el-form-item :rules="rules.body" label=" " prop="messageDetailVOList.0.body">
        <rich-text ref="body" v-model="form.messageDetailVOList[0].body">
          <template #button>
            <span style="margin-left: 5px">
              Characters count
              {{ getCharactersCount() }}
            </span>
          </template>
        </rich-text>
      </el-form-item>
      <!--发件账号-->
      <el-form-item :label="$t('page.sendingAccount')" prop="account">
        <el-select v-model="form.account" disabled />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSubmit">
          {{ $t('title.send') }}
        </el-button>
        <el-button type="primary" @click="handleTimedSend">
          {{ $t('page.timedSend') }}
        </el-button>
        <el-button @click="handleBack">
          {{ $t('order.Cancel') }}
        </el-button>
      </el-form-item>
    </el-form>
    <send-regularly-dialog
      :params="submitParams"
      :query-id="queryId"
      :visible.sync="sendRegularlyDialogVisible"
    />
  </div>
</template>

<script>
import { getMailAccountAll, getTemplate, messagesGetLabel } from '@/api/service-management'
import Upload from '@/components/Upload'
import SendRegularlyDialog from './SendRegularlyDialog'
import RichText from '@/components/RichText/index.vue'
import { getOrderInfoByOrderNo, sendMail } from './api'
import { queryTerraceList } from '@/api/omsorder'

export default {
  name: 'SendEmail',
  components: {
    RichText,
    Upload,
    SendRegularlyDialog
  },
  data() {
    const requiredSelect = [
      {
        required: true,
        trigger: 'change',
        message: this.$t('page.selectPlaceholder')
      }
    ]
    return {
      ossUrl: process.env['VUE_APP_UPLOAD_API'],
      sendRegularlyDialogVisible: false,
      topicTemplate: '',
      fileList: [],
      form: {
        rmaId: '',
        buyerId: '',
        platform: '',
        site: '',
        shop: '',
        tag: [],
        orderCoder: '',
        messageDetailVOList: [
          {
            recipientId: '',
            mediaList: [],
            subject: '',
            body: ''
          }
        ],
        account: ''
      },
      rules: {
        tag: requiredSelect,
        orderCoder: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('page.inputPlaceholder')
          }
        ],
        buyerId: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('page.inputPlaceholder')
          }
        ],
        recipientId: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('page.inputPlaceholder')
          }
        ],
        subject: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('page.inputPlaceholder')
          }
        ],
        body: [
          {
            required: true,
            trigger: 'input',
            message: this.$t('page.inputPlaceholder')
          }
        ],
        platform: requiredSelect,
        site: requiredSelect,
        shop: requiredSelect,
        account: requiredSelect
      },
      platformOptions: [],
      siteOptions: [],
      shopOptions: [],
      mainTemplate: '',
      tagData: [],
      templateList: []
    }
  },
  computed: {
    language() {
      return this.$store.state.app.language
    },
    queryId() {
      return this.$route.query.orderNumber
    },
    topicTemplateList() {
      return this.templateList.filter(e => +e.templateType === 1)
    },
    contentTemplateList() {
      return this.templateList.filter(e => e.templateType?.toString() === '0')
    },
    submitParams() {
      const { messageDetailVOList, tag } = this.form
      messageDetailVOList[0].mediaList = this.fileList.map(e => ({
        mediaName: e.name,
        mediaURL: e.fileUrl
      }))
      return {
        ...this.form,
        messageMarkLabelList: tag.reduce((pre, e, index) => {
          if (index === 0) {
            pre[0]['firstLabelId'] = e
          }
          if (index === 1) {
            pre[0]['secondLabelId'] = e
          }
          return pre
        }, [{}])
      }
    }
  },
  async created() {
    this.$watch(() => this.form.messageDetailVOList[0].subject, () => {
      if (this.notTrigger) {
        return
      }
      this.topicTemplate = ''
    })
    this.$watch(() => this.form.messageDetailVOList[0].body, () => {
      if (this.notTrigger) {
        return
      }
      this.mainTemplate = ''
    })
    await Promise.all([this.getMakeList(), this.getTemplate(), this.getPlatformOptions()])
    await this.getOrderInfoByQuery()
  },
  methods: {
    handleUpload() {
      this.uploading = this.$loading({
        fullscreen: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    getFileLimitMessage(max) {
      const kb = 1024
      const m = kb * 1024
      let msg
      if (max < kb) {
        msg = `${max} B`
      } else if (max < m) {
        msg = `${(max / kb).toFixed(2)} KB`
      } else {
        msg = `${(max / m).toFixed(2)} M`
      }
      return msg
    },
    async getPlatformOptions() {
      const { datas } = await queryTerraceList()
      this.platformOptions = datas.filter((item) =>
        item.platformCode === 'ebay' ||
              item.platformCode === 'walmart' ||
              item.platformCode === 'standwebsite'
      )
    },
    async getSiteOptions() {
      const { platform } = this.submitParams
      const { datas } = await getMailAccountAll(platform)
      if (datas?.length) {
        this.siteOptions = datas[0].siteList
      } else {
        this.siteOptions = []
      }
      this.shopOptions = datas
    },
    handlePlatformChange() {
      this.form.site = ''
      this.form.shop = ''
      this.form.account = ''
      this.getSiteOptions()
    },
    handleShopChange(val) {
      this.form.account = val
      const value = this.shopOptions.find(e => e.account === val)
      this.siteOptions = value?.siteList || []
      this.form.site = ''
    },
    convertBody(val) {
      if (!val) {
        return ''
      }
      return val
        .replaceAll('客户昵称', this.buyerName || '')
        .replaceAll('客服名称', this.$store.state.user.name || '')
        .replaceAll('账号名称', (this.form.account || ''))
        .replace(/\n/g, '<br>')
        .replace(/[ \t]/g, '&nbsp;')
        .replace(/[\n\r]/g, '<br>')
    },
    handleTopicTemplateChange(val) {
      this.notTrigger = true
      this.$nextTick(() => {
        this.notTrigger = false
      })
      const typeName = val.typeName
      const templateTitle = val.templateTitle
      this.form.messageDetailVOList[0].subject = val.templateTitle
      const mainTemplate = this.contentTemplateList.find(e => e.templateTitle === templateTitle && e.typeName === typeName)
      if (mainTemplate) {
        this.mainTemplate = mainTemplate.templateContent
        const convertBody = this.convertBody(mainTemplate.templateContent)
        this.form.messageDetailVOList[0].body = convertBody
        this.$refs.body?.setContent(convertBody)
      }
      if (this.queryId) {
        return
      }
      const flatTagData = this.tagData.reduce((pre, e) => {
        if (e.childNodes) {
          pre.push(...e.childNodes)
        }
        return pre
      }, [])
      const tag = flatTagData.find(e => e.labelName === typeName)
      if (tag) {
        this.form.tag = [tag.parentId, tag.id]
      }
    },
    handleMainTemplateChange(val) {
      this.notTrigger = true
      const convertBody = this.convertBody(val)
      this.form.messageDetailVOList[0].body = convertBody
      this.$refs.body?.setContent(convertBody)
      this.$nextTick(() => {
        this.notTrigger = false
      })
    },
    getCharactersCount() {
      return this.$refs.body?.getHtmlStrCharLength(this.form.messageDetailVOList[0].body) || 0
    },
    async getOrderInfoByQuery() {
      if (this.queryId) {
        const manualWorkOrder = this.tagData.find(e => e.labelNameEN === 'Manual work order')
        if (!manualWorkOrder) {
          this.$message.warning('未找到标签')
        }
        const selfServiceReturn = manualWorkOrder?.childNodes?.find(e => e.labelNameEN === 'Self-service return issues')
        this.form.tag = [manualWorkOrder.id, selfServiceReturn?.id]
        this.form.orderCoder = this.queryId
        this.form.platform = this.$route.query.platform
        this.form.site = this.$route.query.site
        await this.getSiteOptions()
        this.form.shop = this.shopOptions.find(e => e.accountDisplayName === this.$route.query.shop)?.account
        this.form.account = this.form.shop
        this.form.rmaId = this.$route.query.rmaNumber
        await this.getOrderInfo()
      }
    },
    getOrderInfoValidate() {
      return new Promise(resolve => {
        const res = []
        this.$refs.form.validateField(['platform', 'site', 'shop', 'orderCoder'], error => {
          res.push(error)
        })
        resolve(res.every(e => !e))
      })
    },
    async getOrderInfo() {
      const valid = await this.getOrderInfoValidate()
      if (valid) {
        const { platform, site, shop, orderCoder: orderNum } = this.submitParams
        const shopName = this.shopOptions.find(e => e.account === shop)?.accountDisplayName
        const queryParams = {
          orderNum,
          platform,
          site,
          shop: shopName
        }
        const loading = this.$loading({
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        const { datas } = await getOrderInfoByOrderNo(queryParams).finally(() => {
          loading.close()
        })
        this.buyerName = datas?.buyerName
        this.form.buyerId = datas?.buyerName
        this.form.messageDetailVOList[0].recipientId = datas?.buyerEmail
      }
    },
    async getTemplate() {
      const { datas } = await getTemplate({
        current: 1,
        size: 1000,
        typeName: this.queryId ? '自助退货问题' : '',
        templateStatus: 0
      })
      this.templateList = (datas?.records || []).filter(e => e.isUsed)
    },
    handleRemove(delIndex) {
      this.fileList.splice(delIndex, 1)
    },
    async getMakeList() {
      const { datas } = await messagesGetLabel(false)
      this.tagData = (datas || []).filter(e => e.labelNameEN === 'Manual work order')
    },
    handleTimedSend() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.sendRegularlyDialogVisible = true
        } else {
          this.$message.warning(this.$t('page.inputTip'))
        }
      })
    },
    handleBack() {
      if (this.queryId) {
        location.href = `${process.env.VUE_APP_MIRMILES_API}/after-sales/audit-list`
      } else {
        this.$router.go(-1)
      }
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const loading = this.$loading({
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          const { msg } = await sendMail(this.submitParams).finally(() => {
            loading.close()
          })
          this.$message.success(msg)
          this.handleBack()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.attachment {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
  margin-left: -20px;
  margin-bottom: 18px;
  width: calc(100% + 40px);
  border-radius: 5px;
  background: #C6E2FF99;
}
</style>
